import math from "mathjs"

const SEPARATOR = "/"

var round = function(value) {
  if (!value) {
    return 0
  } else if (value < 1) {
    return math.round(value, 3)
  } else if (value < 10) {
    return math.round(value, 2)
  } else if (value < 100) {
    return math.round(value, 1)
  } else {
    return math.round(value, 0)
  }
}

var EnergyUtils = {
  formatWattHours(value) {
    return this.formatKiloWattHours(this.convertWattHours(value))
  },

  formatKiloWattHours(kiloWattHours) {
    return round(kiloWattHours).toLocaleString("en") + " kWh"
  },

  convertWattHours(value) {
    return Math.round(value / 1000)
  },

  formatKiloWatts(kiloWatts) {
    return round(kiloWatts).toLocaleString("en") + " kW"
  },

  formatWatts(value) {
    return this.formatKiloWatts(this.convertWatts(value))
  },

  convertWatts(value) {
    return Math.round(value / 1000)
  },

  filterOutChildNodes(asset, children) {
    let childSourceIds = []
    children.forEach(child => {
      childSourceIds = childSourceIds.concat(
        child.nodesStatus.map(nodeStatus => {
          return nodeStatus.sourceId
        })
      )
    })
    return asset.nodesStatus.filter(nodeStatus => {
      return !childSourceIds.includes(nodeStatus.sourceId)
    })
  },

  getSites(project) {
    return Object.values(project.sites).sort((a, b) => {
      return a.code.localeCompare(b.code)
    })
  },

  getSystems(site) {
    return Object.values(site.systems).sort((a, b) => {
      return a.code.localeCompare(b.code)
    })
  },

  getAsset(assetArray, code) {
    return assetArray.find(asset => {
      return asset.code === code
    })
  },

  isGenerationDatum(source) {
    let deviceType = this.getDeviceType(source)
    // return deviceType === 'INV'
    return deviceType === "GEN"
  },

  isConsumptionDatum(source) {
    let deviceType = this.getDeviceType(source)
    // return deviceType === 'MET'
    return deviceType === "CON"
  },

  isExportDatum(source) {
    let deviceType = this.getDeviceType(source)
    // return deviceType === 'MET'
    return deviceType === "EXP"
  },

  isStorageDatum(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "STO"
  },

  isIrradianceDatum(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "PYR"
  },

  isLoadDatum(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "LOAD"
  },

  isInverterDatum(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "INV"
  },

  isConsumingSource(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "CON" || deviceType === "LOAD" || deviceType === "MET" || deviceType === "EXP"
  },

  isGeneratingSource(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "GEN" || deviceType === "INV"
  },

  getDeviceType(source) {
    let sourceId = source.sourceId ? source.sourceId : source
    let parts = sourceId.split(SEPARATOR)
    if (parts.length > 4) {
      return parts[4]
    }
    return null
  },

  getGenerationLast24Hours(asset, assetStatus) {
    if (assetStatus) {
      let gen = assetStatus.nodesStatus.reduce((total, nodeStatus) => {
        if (nodeStatus.wattHours && this.isGenerationDatum(nodeStatus)) {
          total = math.add(total, this.convertWattHours(nodeStatus.wattHours)) // Get the value i kWh
        }
        return total
      }, 0)

      return math.round(gen, 2)
    }
    return 0
  },

  getPercentExpectedConsumption(projectReading, expectedConsumption) {
    if (projectReading && projectReading.consumption && expectedConsumption && expectedConsumption.expectedConsumption) {
      return math.round(math.multiply(math.divide(projectReading.consumption, expectedConsumption.expectedConsumption), 100)) + "%"
    }
  },

  getPercentExpectedGeneration(projectReading, expectedGeneration) {
    if (projectReading && projectReading.generation && expectedGeneration && expectedGeneration.expectedGeneration) {
      return math.round(math.multiply(math.divide(projectReading.generation, expectedGeneration.expectedGeneration), 100)) + "%"
    }
  },

  getPercentPredictedGeneration(projectReading, forecastGeneration) {
    if (projectReading && projectReading.generation && forecastGeneration && forecastGeneration.forecastGeneration) {
      return math.round(math.multiply(math.divide(projectReading.generation, forecastGeneration.forecastGeneration), 100)) + "%"
    }
  }
}

export default EnergyUtils
