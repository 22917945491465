import * as React from "react"
import { ListGroup, ListGroupItem } from "reactstrap"
import PublicProjectService from "./project/PublicProjectService"

export default function RenderProjects({ loading, match }) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [projects, setProjects] = React.useState([])

  React.useEffect(() => {
    PublicProjectService.getProjects(match.params.orgId).then(data => {
      setProjects(data.projects)
      setIsLoading(false)
    })
  }, [])

  if (loading || isLoading) {
    return <div style={{ textAlign: "center" }}>Loading data...</div>
  }

  if (projects.length === 0 && loading === false && isLoading === false) {
    return <div style={{ textAlign: "center" }}>This organization has no projects</div>
  }

  if (projects) {
    return (
      <div className="projects">
        <ListGroup className="projects-list">
          {projects.map(project => {
            return (
              <ListGroupItem className="project-link" key={project.code}>
                <a href={`${match.params.orgId}/${project.code}`}>{project.name}</a>
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
    )
  }
}
