import math from "mathjs"

var round = function(value) {
  if (!value) {
    return 0
  } else if (value < 1) {
    return math.round(value, 3)
  } else if (value < 10) {
    return math.round(value, 2)
  } else if (value < 100) {
    return math.round(value, 1)
  } else {
    return math.round(value, 0)
  }
}

var EnergyUtils = {
  convertToKilo(value) {
    return round(value / 1000)
  },

  convertToMega(value) {
    return round(value / 1000000)
  },

  formatNumber(value) {
    if (value) {
      let maximumFractionDigits = 0
      if (Math.abs(value) < 1) {
        maximumFractionDigits = 3
      } else if (Math.abs(value) < 10) {
        maximumFractionDigits = 2
      } else if (Math.abs(value) < 100) {
        maximumFractionDigits = 1
      }
      return value.toLocaleString("en", { maximumFractionDigits: maximumFractionDigits })
    }
  },

  formatNumberAsKilo(value) {
    return this.formatNumber(this.convertToKilo(value))
  },

  formatNumberAsMega(value) {
    return this.formatNumber(this.convertToMega(value))
  },

  // WattHours

  displayWattHours(wattHours) {
    let abs = Math.abs(wattHours)
    if (abs < 1000) {
      return this.formatWattHours(wattHours)
    } else if (abs < 1000000) {
      return this.formatKiloWattHours(this.convertToKilo(wattHours))
    } else {
      return this.formatMegaWattHours(this.convertToMega(wattHours))
    }
  },

  formatWattHoursAsKiloWattHours(value) {
    return this.formatKiloWattHours(this.convertToKilo(value))
  },

  formatWattHoursAsMegaWattHours(value) {
    return this.formatMegaWattHours(this.convertToMega(value))
  },

  formatWattHours(wattHours) {
    return round(wattHours).toLocaleString("en") + " Wh"
  },

  formatKiloWattHours(kiloWattHours) {
    return round(kiloWattHours).toLocaleString("en") + " kWh"
  },

  formatMegaWattHours(megaWattHours) {
    return round(megaWattHours).toLocaleString("en") + " MWh"
  }
}

export default EnergyUtils
