var DatumUtils = {
  groupDatums(assets) {
    if (assets) {
      let datums = assets.reduce((datums, asset) => {
        if (asset.aggregatedTotals) {
          Object.keys(asset.aggregatedTotals).forEach(dateKey => {
            let datum = asset.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[asset.code] = {
                generation: datum.generation,
                normalisedGeneration: datum.normalisedGeneration,
                consumption: datum.consumption,
                export: datum.export,
                generationReading: datum.generationReading,
                consumptionReading: datum.consumptionReading
              }

              // Set up the datum for the date
              datums[dateKey] = {
                generation: datum.generation,
                consumption: datum.consumption,
                export: datum.export,
                generationReading: datum.generationReading,
                consumptionReading: datum.consumptionReading,
                max: {
                  generation: {
                    asset: asset.code,
                    watts: datum.generation
                  },
                  consumption: {
                    asset: asset.code,
                    watts: datum.consumption
                  }
                },
                assetDatums: assetDatums
              }
            } else {
              datums[dateKey].generation += datum.generation
              datums[dateKey].consumption += datum.consumption
              datums[dateKey].export += datum.export
              datums[dateKey].generationReading += datum.generationReading
              datums[dateKey].consumptionReading += datum.consumptionReading

              if (datum.generation > datums[dateKey].max.generation.watts) {
                datums[dateKey].max.generation = {
                  asset: asset.code,
                  watts: datum.generation
                }
              }
              if (datum.consumption > datums[dateKey].max.consumption.watts) {
                datums[dateKey].max.consumption = {
                  asset: asset.code,
                  watts: datum.consumption
                }
              }

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[asset.code] = {
                generation: datum.generation,
                normalisedGeneration: datum.normalisedGeneration,
                consumption: datum.consumption,
                export: datum.export,
                generationReading: datum.generationReading,
                consumptionReading: datum.consumptionReading
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function(key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  }
}

export default DatumUtils
