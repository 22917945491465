import { Cache } from "aws-amplify"
import fetch from "node-fetch"
import moment from "moment"
import Logger from "./Logger"

const API = {
  pendingGets: {},

  clearCache() {
    Cache.clear()

    // We give the more immediate data a chance to load before warming the local cache
    setTimeout(this.warmCache, 1000)
  },

  getFromCache(url, expires) {
    let cachedValue = Cache.getItem(url)
    if (cachedValue) {
      return Promise.resolve(cachedValue)
    } else {
      return this.cacheGet(url, expires)
    }
  },

  cacheGet(url, expires) {
    if (this.pendingGets[url]) {
      // If the same request is already pending then we just wait for it
      return this.pendingGets[url]
    }

    let request = this.get(url).then(result => {
      if (expires) {
        Cache.setItem(url, result, { expires: expires })
      } else {
        Cache.setItem(url, result)
      }
      return result
    })

    this.pendingGets[url] = request

    return request
  },

  get(url) {
    return fetch(process.env.REACT_APP_ECOGY_API_URL + url, {})
      .then(response => {
        return this.handleResponse(response)
      })
      .catch(err => {
        return this.handleError(err)
      })
  },

  handleResponse(response) {
    if (response.ok) {
      if (response.headers.get("Content-Type") === "application/json") {
        return response.json()
      } else {
        return response
      }
    } else if (response.status === 404) {
      return null
    } else {
      return response.json().then(error => {
        if (error && (error === "No current user" || error.code === "NotAuthorizedException")) {
          Logger.info("session expired, reloading page")
          window.location.reload() // Reload the page to show the login screen
        } else {
          return Promise.reject(error)
        }
      })
    }
  },

  handleError(err) {
    if (err && (err === "No current user" || err.code === "NotAuthorizedException")) {
      Logger.info("session expired, reloading page")
      window.location.reload() // Reload the page to show the login screen
    } else {
      return Promise.reject(err)
    }
  },

  getDefaultCacheTimeout() {
    return this.getCacheTimeout(15)
  },

  getCacheTimeout(minutes) {
    return moment()
      .add(minutes, "minutes")
      .toDate()
  },

  addRangeToUrl(url, range) {
    let start = moment(range.start).format("YYYY-MM-DDTHH:mm")
    let end = moment(range.end).format("YYYY-MM-DDTHH:mm")
    return (url += (url.indexOf("?") > 0 ? "&" : "?") + "start=" + start + "&end=" + end)
  }
}

export default API
