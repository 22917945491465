/**
 * Common logging class.
 */

var Logger = {
  // eslint-disable-next-line no-console
  debug: process.env.REACT_APP_DEBUG ? Function.prototype.bind.call(console.debug, console, "DEBUG") : () => {},

  // eslint-disable-next-line no-console
  info: Function.prototype.bind.call(console.info, console, "INFO"),

  // eslint-disable-next-line no-console
  warning: Function.prototype.bind.call(console.warn, console, "WARNING"),

  // eslint-disable-next-line no-console
  error: Function.prototype.bind.call(console.error, console, "ERROR")
}

export default Logger
