import React from "react"
import { Button, Label } from "reactstrap"
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom"
import EcogyComponent from "./common/EcogyComponent"
import PublicProjectService from "./project/PublicProjectService"
import logo from "./img/ecosuite_logo.png"

import "./App.scss"
import RenderProjects from "./RenderProjects"
import RenderProject from "./RenderProject"
import RenderOrganizations from "./RenderOrganizations"

class App extends EcogyComponent {
  constructor(props) {
    super(props)
    this.noMatch = this.noMatch.bind(this)
    this.state = {
      organizations: [],
      loading: false
    }
  }

  componentDidMount() {
    this.setState({ ...this.state, loading: true })
    PublicProjectService.getOrganizations().then(data => {
      this.setState({ ...this.state, organizations: data, loading: false })
    })
  }

  renderContent() {
    return (
      <Router>
        <div className="app">
          <header className="app-header">
            <Link to="/">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </header>
          <Switch>
            <Route exact path="/" render={() => <RenderOrganizations organizations={this.state.organizations} loading={this.state.loading} />} />
            <Route exact path="/organizations/:orgId" render={params => <RenderProjects organizations={this.state.organizations} loading={this.state.loading} {...params} />} />
            <Route path="/organizations/:orgId/:projectId" render={params => <RenderProject {...params} />} />
            <Route component={this.noMatch} />
          </Switch>
        </div>
      </Router>
    )
  }

  noMatch() {
    return (
      <div className="no-match">
        <Label className="not-found">The page you requested was not found.</Label>
        <div className="projects-button">
          <Button>
            <a href="/">Click here for a list of public organizations</a>
          </Button>
        </div>
      </div>
    )
  }
}

export default App
