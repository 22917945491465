import React, { Component } from "react"
import { Alert } from "reactstrap"
import logoRound from "../img/logo.png"

import MaterialIcon from "material-icons-react"

class EcogyComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  isComponentMounted() {
    return this._isMounted
  }

  setStateIfMounted(state, callback) {
    if (this.isComponentMounted()) {
      super.setState(state, callback)
    }
  }

  componentDidCatch(error) {
    this.setState({ hasError: true, error: error })
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // A change in props may remove the error so we reset hasError
  //   return { hasError: false, error: undefined };
  // }

  componentDidUpdate() {
    // A change in props may remove the error so we reset hasError
    return { hasError: false, error: undefined }
  }

  isContentValid(data) {
    if (data && !this.isContentError(data)) {
      return true
    }
    return false
  }

  isContentError(data) {
    return data instanceof EcogyComponentError
  }

  render() {
    if (this.state.hasError) {
      return this.renderError()
    } else {
      return this.renderContent()
    }
  }

  renderError() {
    return <Error error={this.state.error} />
  }

  renderLoading() {
    return <Loading />
  }

  renderContent() {
    return this.renderLoading()
  }
}

class TODO extends Component {
  render() {
    return (
      <div className="app-todo">
        <img src={logoRound} className="app-logo" alt="logo" />
        <div>Coming Soon</div>
      </div>
    )
  }
}

class Loading extends Component {
  render() {
    return (
      <div className="loading">
        <img src={logoRound} className="app-logo" alt="logo" />
      </div>
    )
  }
}

class Error extends Component {
  render() {
    return (
      <div className="Error">
        <h2 className="error-title">
          <MaterialIcon icon="error_outline" size="large" /> Error
        </h2>
        <p className="error-message">{this.props.error.message}</p>
      </div>
    )
  }
}

class Warning extends Component {
  render() {
    return (
      <div className="Warning">
        <h2 className="warning-title">
          <MaterialIcon icon="warning" size="large" /> {this.props.title ? this.props.title : "Warning"}
        </h2>
        <p className="warning-message">{this.props.message}</p>
      </div>
    )
  }
}

class FormError extends Component {
  getFormErrors(error) {
    if (error) {
      return (
        <div className="error-messages">
          <Alert color="danger">{error.message ? error.message : typeof error === "object" ? JSON.stringify(error) : error}</Alert>
          {this.getNestedValidationErrors(error.errors)}
        </div>
      )
    }
    return null
  }

  getNestedValidationErrors(errors) {
    if (errors) {
      return errors.map((error, idx) => {
        return (
          <Alert key={"error-" + idx} color="danger">
            {error.dataPath + ": " + error.message}
          </Alert>
        )
      })
    }
    return null
  }

  render() {
    return this.getFormErrors(this.props.error)
  }
}

/**
 * Used to identify that an error has occured and that the componnet should report this.
 */
class EcogyComponentError {
  constructor(error) {
    this.error = error
  }

  getError() {
    return this.error
  }
}

export { Loading, TODO, FormError, Error, Warning, EcogyComponentError }
export default EcogyComponent
