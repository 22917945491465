import * as React from "react"
import { ListGroup, ListGroupItem } from "reactstrap"

export default function RenderOrganizations({ organizations, loading }) {
  if (loading) {
    return <div style={{ textAlign: "center" }}>Loading data...</div>
  }

  if (organizations) {
    return (
      <div className="projects">
        <ListGroup className="projects-list">
          {organizations.map(org => {
            return (
              <ListGroupItem className="project-link" key={org.id} style={{ display: "flex", justifyContent: "center" }}>
                <a href={"organizations/" + org.slug}>{org.name}</a>
                {org.settings?.logoUrl ? <img src={org.settings.logoUrl} height="30px" style={{ border: "1px solid white", marginLeft: "16px" }} /> : null}
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
    )
  }
}
