import math from "mathjs"

const POUNDS_IN_TON = 2000 // 1 US ton = 2000 pounds
const METRIC_TONNE_TO_US_TON = 1.10231
const POUNDS_CO2_IN_ONE_GALLON_GAS = 19.64 // Burning 1 gallon gas releases 19.64 pounds of CO2.

var EnvironmentUtils = {
  /**
   * Converts the avoided emissions into tonnes saved
   * @param {*} avoidedEmissions The avoided emissions in kgs
   */
  getTonnesSaved(avoidedEmissions) {
    if (avoidedEmissions) {
      // avoided emissions are provided as kg so we convert to tonnes
      return math.divide(avoidedEmissions, 1000)
    }
  },

  getUSTonsSaved(avoidedEmissions) {
    if (avoidedEmissions) {
      return math.divide(this.getTonnesSaved(avoidedEmissions), METRIC_TONNE_TO_US_TON)
    }
  },

  /**
   * Converts the avoided emissions into gallons of gasoline saved.
   * Burning 1 gallon gas releases 19.64 pounds of CO2.
   * 1 US ton = 2000 pounds.
   *
   * @param {*} avoidedEmissions The avoided emissions in kgs
   */
  getGasolineSaved(avoidedEmissions) {
    if (avoidedEmissions) {
      let avoidedEmissionsInPounds = math.multiply(this.getUSTonsSaved(avoidedEmissions), POUNDS_IN_TON)
      return math.divide(avoidedEmissionsInPounds, POUNDS_CO2_IN_ONE_GALLON_GAS)
    }
    return null
  }
}

export default EnvironmentUtils
