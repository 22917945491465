import * as React from "react"
import PublicProjectService from "./project/PublicProjectService"
import ProjectDetails from "./project/ProjectDetails"

export default function RenderProject({ match }) {
  const [project, setProject] = React.useState(null)

  React.useEffect(() => {
    const orgId = match.params.orgId
    const projectId = match.params.projectId
    PublicProjectService.getProject(orgId, projectId).then(project => {
      setProject(project)
    })
  }, [])
  if (project) {
    let params = new URLSearchParams(window.location.search)
    return <ProjectDetails showSites={params.has("showSites")} showGraphs={params.has("showGraphs")} project={project} organizationId={project.organizationId} />
  } else {
    return null
  }
}
