import React from "react"
import { Label, ListGroup, ListGroupItem } from "reactstrap"
import EcogyComponent, { Loading } from "../common/EcogyComponent"
import PublicProjectService from "./PublicProjectService"
import DatumUtils from "./graph/DatumUtils"
import ProjectGenerationGraph from "./graph/ProjectGenerationGraph"
import ProjectGenerationPowerGraph from "./graph/ProjectGenerationPowerGraph"
import EnergyUtils from "./EnergyUtils"
import EnvironmentUtils from "./EnvironmentUtils"

import gas from "../img/gas.png"

export default class ProjectDetails extends EcogyComponent {
  componentDidMount() {
    super.componentDidMount()
    this.loadGraphDatums()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    if (prevProps.project && prevProps.project !== this.props.project) {
      this.loadGraphDatums()
    }
  }

  loadGraphDatums() {
    this.setState({ dayDatums: null, weekDatums: null })
    PublicProjectService.getProjectReadings(this.props.project.code, this.props.organizationId).then(projectReadings => {
      this.setStateIfMounted({ projectReadings: projectReadings.project })
    })
    PublicProjectService.getProjectDatums(this.props.project.code, "day").then(datums => {
      this.setStateIfMounted({ dayDatums: datums.project, dayGraphDatums: DatumUtils.groupDatums([datums.project]), dayAggregation: datums.aggregation, dayRange: datums.range })
    })
    PublicProjectService.getProjectDatums(this.props.project.code, "week").then(datums => {
      this.setStateIfMounted({ weekDatums: datums.project, weekGraphDatums: DatumUtils.groupDatums([datums.project]), weekAggregation: datums.aggregation, weekRange: datums.range })
    })
  }

  renderContent() {
    return (
      <div className="project-details">
        <div className="project-name">
          <h2>
            <Label className="identifier">{this.props.project.name}</Label>
          </h2>
        </div>
        <ListGroup className="project-details">
          <ListGroupItem key="address">{this.props.project.address}</ListGroupItem>
          <ListGroupItem key="start-date">Interconnection Date: {this.props.project.productionStartDate}</ListGroupItem>
          <ListGroupItem key="project-size">Nameplate Capacity: {this.props.project.dcSize} kW DC</ListGroupItem>
        </ListGroup>
        {this.renderReadings()}
        {this.props.showSites ? <SiteDetails sites={this.props.project.sites} /> : null}
        {this.renderGraphs()}
      </div>
    )
  }

  renderReadings() {
    if (this.state.projectReadings) {
      let readings = this.state.projectReadings
      return (
        <div>
          {readings.generation ? (
            <p>
              <span className="reading">{EnergyUtils.displayWattHours(readings.generation)}</span>
              {" generated"}
              {readings.avoidedEmissions ? ", avoiding emissions of " + EnergyUtils.formatNumber(EnvironmentUtils.getUSTonsSaved(readings.avoidedEmissions)) + " tons of CO2" : null}
            </p>
          ) : null}

          {readings.generation && readings.avoidedEmissions ? (
            <p>
              <img src={gas} width="40px" style={{ verticalAlign: "middle" }} /> equivalent to{" "}
              <span className="reading">{EnergyUtils.formatNumber(EnvironmentUtils.getGasolineSaved(readings.avoidedEmissions))}</span> Gallons of Gas
            </p>
          ) : null}

          {readings.consumption ? (
            <p>
              <span className="reading">{EnergyUtils.displayWattHours(readings.consumption)}</span>
              {" consumed"}
            </p>
          ) : null}
        </div>
      )
    }
  }

  renderGraphs() {
    if (this.props.showGraphs) {
      return (
        <div>
          {this.state.dayDatums ? (
            <React.Fragment>
              <h4 className="identifier">24 Hour Power (kW)</h4>
              <ProjectGenerationPowerGraph
                assets={[this.props.project]}
                datums={this.state.dayDatums}
                graphDatums={this.state.dayGraphDatums}
                aggregation={this.state.dayAggregation}
                range={this.state.dayRange}
              />
            </React.Fragment>
          ) : (
            <Loading />
          )}
          {this.state.weekDatums ? (
            <React.Fragment>
              <h4 className="identifier">7 Day Energy (kWh)</h4>
              <ProjectGenerationGraph
                assets={[this.props.project]}
                datums={this.state.weekDatums}
                graphDatums={this.state.weekGraphDatums}
                aggregation={this.state.weekAggregation}
                range={this.state.weekRange}
              />
            </React.Fragment>
          ) : (
            <Loading />
          )}
        </div>
      )
    }
  }
}

class SiteDetails extends EcogyComponent {
  renderContent() {
    let sites = this.props.sites
    return (
      <div className="project-sites">
        {Object.keys(sites).map(siteID => {
          const site = sites[siteID]
          return (
            <div key="site-details" className="site-details">
              <Label className="identifier">{site.name}</Label>
              <ListGroup className="site-details">
                <ListGroupItem key="site-address">Site Address: {site.address}</ListGroupItem>
                <ListGroupItem key="production-start-date">Interconnection Date: {site.productionStartDate}</ListGroupItem>
                <ListGroupItem key="site-size">Nameplate Capacity: {site.dcSize} kW DC</ListGroupItem>
                <div className="systems">
                  {Object.keys(site.systems).map(systemID => {
                    const system = site.systems[systemID]
                    return (
                      <div key="system-details" className="system-details">
                        <Label>{system.name}</Label>
                        <ListGroup className="system-details">
                          <ListGroupItem key="system-size">Nameplate Capacity: {system.dcSize} kW DC</ListGroupItem>
                        </ListGroup>
                      </div>
                    )
                  })}
                </div>
              </ListGroup>
            </div>
          )
        })}
      </div>
    )
  }
}
