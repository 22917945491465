import React from "react"
import { ResponsiveContainer, XAxis, YAxis, Tooltip, LineChart, Line, Legend } from "recharts"
import moment from "moment-timezone"

import { Loading } from "../../common/EcogyComponent"
import EnergyUtils from "./EnergyUtils"
import DateRangeGraph, { GRAPH_COLORS } from "./DateRangeGraph"

export default class ProjectGenerationPowerGraph extends DateRangeGraph {
  getData() {
    let data = Object.keys(this.props.graphDatums).map(dateKey => {
      let datum = this.props.graphDatums[dateKey]
      let date = moment(dateKey)
      let entry = {
        date: date.toDate(),
        time: date.toDate().getTime(),
        name: date.format("lll")
      }
      Object.keys(datum.assetDatums).forEach(assetCode => {
        entry.Generation = this.round(datum.assetDatums[assetCode].generationReading)
        entry.Consumption = this.round(datum.assetDatums[assetCode].consumptionReading)
      })
      return entry
    })
    return data
  }

  renderContent() {
    if (this.props.graphDatums && this.props.datums) {
      let data = this.getData()
      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"

      return (
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
            <XAxis dataKey="time" ticks={this.getTicks(data, this.props.range, this.props.aggregation)} tickFormatter={this.dateFormat} />
            <YAxis />

            <Tooltip
              wrapperStyle={{ color: "#000", pointerEvents: "auto" }}
              // eslint-disable-next-line no-unused-vars
              formatter={(value, name, props) => {
                if (this.props.normalise) {
                  return value
                }
                return EnergyUtils.formatKiloWatts(value)
              }}
              labelFormatter={label => {
                return moment(label).format("lll")
              }}
            />

            <Line type={graphType} dataKey={"Generation"} stroke={GRAPH_COLORS[0]} dot={aggregation === "day"} />
            <Line type={graphType} dataKey={"Consumption"} stroke={GRAPH_COLORS[2]} dot={aggregation === "day"} />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      )
    }
    return <Loading />
  }
}
