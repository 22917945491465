import EcogyComponent from "../../common/EcogyComponent"
import moment from "moment-timezone"
import math from "mathjs"

var d3_scale = require("d3-scale")
var d3_time = require("d3-time")

export const GRAPH_COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#c12834",
  "#01BAEF",
  "#FFD65b",
  "#f7a98c",
  "#9DD9D2",
  "#F6511D",
  "#d84add",
  "#3891A6",
  "#9380c9",
  "#00FFC5",
  "#97CC04",
  "#98CE00"
]

export default class DateRangeGraph extends EcogyComponent {
  constructor(props) {
    super(props)

    this.zoomDateRange = this.zoomDateRange.bind(this)
  }

  round(value) {
    if (value) {
      return math.round(math.divide(value, 1000), 3)
    } else {
      return 0
    }
  }

  zoomDateRange(selection) {
    if (selection) {
      let seconds = selection.activeLabel
      if (seconds > 0) {
        let date = moment(seconds)
        if (this.props.zoomToDate) {
          this.props.zoomToDate(date)
        }
      }
    }
  }

  resetZoom() {
    if (this.props.zoomToDate) {
      this.props.zoomToDate()
    }
  }

  getTicks(data, range, aggregation) {
    if (!data || !data.length) {
      return []
    }

    const startDate = moment(range.localStartDate)
    const endDate = moment(range.localEndDate)
    const scale = d3_scale
      .scaleTime()
      .domain([startDate, endDate])
      .range([0, 1])
    let ticks = scale.ticks(d3_time.timeHour, 6)

    if (aggregation === "day") {
      ticks = scale.ticks(d3_time.timeDay, 1)
    }

    return ticks.map(entry => +entry)
  }

  dateFormat(time) {
    if (
      time ===
      moment(time)
        .startOf("day")
        .toDate()
        .getTime()
    ) {
      return moment(time).format("MMM DD")
    } else {
      return moment(time).format("HH:mm")
    }
  }
}
